import React from 'react'
import { useAddress } from '@thirdweb-dev/react'
import styles from './index.module.scss'

export const ProfileLink: React.FC = () => {
  const address = useAddress()

  if (!address) return null

  return (
    <a
      href={ `/profile/${address}` }
      data-testid="profile-link"
    >
      <img
        className={ styles['profile-link__image'] }
        src="/defaults/avatar.png"
        alt="Link to user profile"
      />
    </a>
  )
}
