import React from 'react'
import { useAddress, useChain } from '@thirdweb-dev/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { transakApiKey, transakEnvironment, transakRedirectLink } from '~/constants/transak'
import { getSupportedChains } from '~/components/shared/chain'
import styles from './index.module.scss'

export const BuyCryptoLink: React.FC = () => {
  const address = useAddress()
  const chain = useChain()
  const supportedChains = getSupportedChains()

  // don't let user buy crypto if not connected or connected to unsupported chain
  if (
    !address ||
    !chain ||
    supportedChains.filter((c) => c.chainId === chain.chainId).length === 0
  ) {
    return null
  }

  const keyParam = `apiKey=${transakApiKey}`
  const envParam = `environment=${transakEnvironment}`
  const networkParam = `network=${chain.slug}`
  const walletParam = `walletAddress=${address}`
  const defaultCryptoParam = 'defaultCryptoCurrency=MATIC'

  return (
    <div className={ styles['buy-crypto-link__container'] }>
      <span></span>
      <a
        className={ styles['buy-crypto-link__link'] }
        href={ `${transakRedirectLink}?${keyParam}&${envParam}&${networkParam}&${walletParam}&${defaultCryptoParam}` }
        target="_blank"
      >
        Buy Crypto { }

        <FontAwesomeIcon
          icon={ faArrowRight }
          className={ styles['buy-crypto-link__icon'] }
        />
      </a>
    </div>
  )
}
