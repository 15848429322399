import { Arbitrum, ArbitrumSepolia, CronosTestnet, Polygon, PolygonAmoyTestnet, Chain } from '@thirdweb-dev/chains'
import { ChilizMainnet, SpicyTestnet } from '~/constants/chiliz'
import { environment } from '~/constants/environment'

export const getChainNameFromId = (id: number): string | undefined => {
  switch (id) {
  case 137:
    return 'Polygon'

  case 338:
    return 'Cronos Testnet'

  case 80002:
    return 'Amoy'

  case 88882:
    return 'Chiliz Spicy Testnet'

  case 88888:
    return 'Chiliz Chain'

  default:
    return undefined
  }
}

export const getChainExplorerLink = (chainId: number, address?: string): string | undefined => {
  const polygonscan = 'https://polygonscan.com'
  const amoyscan = 'https://www.oklink.com/amoy'
  const spicyscan = 'https://spicy-explorer.chiliz.com'
  const chilizscan = 'https://scan.chiliz.com'
  const cronosscan = 'https://explorer.cronos.org/testnet'
  const arbitrumsepoliascan = 'https://sepolia.arbiscan.io'
  const arbitrumscan = 'https://arbiscan.io'

  switch (chainId) {
  case 137:
    return address ? `${polygonscan}/address/${address}` : polygonscan

  case 338:
    return address ? `${cronosscan}/address/${address}` : cronosscan

  case 80002:
    return address ? `${amoyscan}/address/${address}` : amoyscan

  case 88882:
    return address ? `${spicyscan}/address/${address}` : spicyscan

  case 88888:
    return address ? `${chilizscan}/address/${address}` : chilizscan

  case 42161:
    return address ? `${arbitrumscan}/address/${address}` : arbitrumscan

  case 421614:
    return address ? `${arbitrumsepoliascan}/address/${address}` : arbitrumsepoliascan

  default:
    return undefined
  }
}

export const getSupportedChains = (): Chain[] => {
  return environment === 'production'
    ? [Polygon, ChilizMainnet, Arbitrum]
    : [PolygonAmoyTestnet, CronosTestnet, SpicyTestnet, ArbitrumSepolia]
}
