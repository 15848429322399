import { BigNumber } from 'ethers'

export type MatchPoolAction = 'stake' | 'unstake'

export type Sex = 'men' | 'women'

export enum Winner {
  PENDING = 0,
  HOME,
  AWAY,
  DRAW
}

export enum EuroPhase {
  ROUND_OF_16 = 0,
  QUARTER_FINALS,
  SEMI_FINALS,
  FINAL
}

export interface MatchContract {
  chainId: number
  address: string
}

interface Team {
  name: string
  flagUrl: string
}

interface Phase {
  id: number
  name: string
}

export interface MatchPool {
  id: string
  competition: string
  phase?: Phase
  group?: string
  venue?: string
  contract: MatchContract
  startDate: number
  endDate: number
  teamHome: Team
  teamAway: Team
  winner: Winner
  scoreHome?: number
  scoreAway?: number
  penaltyScoreHome?: number
  penaltyScoreAway?: number
  sex?: Sex
}

export interface MatchPoolStake {
  amount: BigNumber
  claimed: boolean
}
