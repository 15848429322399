
export const chilizGenesisChainId = parseInt(String(process.env.NEXT_PUBLIC_CHILIZ_GENESIS_CHAIN_ID))
export const chilizGenesisContractAddress = String(process.env.NEXT_PUBLIC_CHILIZ_GENESIS_CONTRACT)
export const chilizGenesisFee = String(process.env.NEXT_PUBLIC_CHILIZ_GENESIS_FEE)

export const legendClubChilizChainId = parseInt(String(process.env.NEXT_PUBLIC_LEGEND_CLUB_CHILIZ_CHAIN_ID))
export const legendClubContractAddress = String(process.env.NEXT_PUBLIC_LEGEND_CLUB_CONTRACT)
export const legendClubFee = String(process.env.NEXT_PUBLIC_LEGEND_CLUB_FEE)

export const SpicyTestnet = {
  chainId: 88882,
  rpc: [ 'https://spicy-rpc.chiliz.com/' ],
  shortName: 'chz',
  slug: 'chiliz-spicy-testnet',
  testnet: true,
  chain: 'CHZ',
  name: 'Chiliz Spicy Testnet',
  faucets: [ 'https://spicy-faucet.chiliz.com/' ],
  explorers: [
    {
      'name': 'spicy-explorer',
      'url': 'https://spicy-explorer.chiliz.com',
      'standard': 'none',
    },
  ],
  nativeCurrency: {
    name: 'Chiliz',
    symbol: 'CHZ',
    decimals: 18,
  },
}

export const ChilizMainnet = {
  chainId: 88888,
  rpc: [ 'https://rpc.ankr.com/chiliz' ],
  shortName: 'cc2',
  slug: 'chiliz-chain',
  testnet: false,
  chain: 'CC2',
  name: 'Chiliz Chain',
  faucets: [ ],
  explorers: [
    {
      'name': 'cc2scan',
      'url': 'https://scan.chiliz.com',
      'standard': 'EIP3091',
    },
  ],
  nativeCurrency: {
    name: 'Chiliz',
    symbol: 'CHZ',
    decimals: 18,
  },
  icon: {
    url: 'ipfs://QmTGYofJ8VLkeNY4J69AvXi8e126kmbHmf34wLFoJ1FKAK',
    width: 400,
    height: 400,
    format: 'png',
  },
  redFlags: [ 'reusedChainId' ],
}
