import React from 'react'
import { defaultChainId } from '~/constants/chain'

interface ChainContextSpec {
  activeChain: number
  setActiveChain: (chain: number) => void
}

export const ChainContext = React.createContext<ChainContextSpec>({
  activeChain: defaultChainId,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveChain: (_chain) => {},
})
