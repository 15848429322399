import React from 'react'
import styles from './index.module.scss'
import Link from 'next/link'

export const PlatformLinks: React.FC = () => {
  return (
    <div
      className={ styles['platform-links__container'] }
      data-testid="platform-links"
    >
      <p className={ styles['platform-links__title'] }>
        Platform
      </p>

      <Link
        className={ styles['platform-links__link'] }
        href="https://factory.belegends.com/"
        target="_blank"
      >
        Legend Factory
      </Link>

      <br/>

      <Link
        className={ styles['platform-links__link'] }
        href="https://museum.belegends.com/"
        target="_blank"
      >
        Legend Museum
      </Link>

      <br/>

      <Link
        className={ styles['platform-links__link'] }
        href="https://verify.belegends.com/"
        target="_blank"
      >
        Legend Verify
      </Link>
    </div>
  )
}
