import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMedium,
  faTelegram,
  faXTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
  faDiscord
} from '@fortawesome/free-brands-svg-icons'
import styles from './index.module.scss'

export const SocialLinks: React.FC = () => {
  return (
    <div
      className={ styles['social-links__container'] }
      data-testid="social-links"
    >
      <a
        className={ styles['social-links__link'] }
        href="https://leverade.medium.com"
        target="_blank"
        rel="noreferrer"
        title="Medium"
      >
        <FontAwesomeIcon
          icon={ faMedium }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://t.me/belegends_ok"
        target="_blank"
        rel="noreferrer"
        title="Telegram"
      >
        <FontAwesomeIcon
          icon={ faTelegram }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://www.linkedin.com/company/leverade"
        target="_blank"
        rel="noreferrer"
        title="LinkedIn"
      >
        <FontAwesomeIcon
          icon={ faLinkedin }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://www.youtube.com/@leverade6858"
        target="_blank"
        rel="noreferrer"
        title="YouTube"
      >
        <FontAwesomeIcon
          icon={ faYoutube }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://www.instagram.com/belegends_ok/"
        target="_blank"
        rel="noreferrer"
        title="Instagram"
      >
        <FontAwesomeIcon
          icon={ faInstagram }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://x.com/belegends_ok"
        target="_blank"
        rel="noreferrer"
        title="Twitter"
      >
        <FontAwesomeIcon
          icon={ faXTwitter }
        />
      </a>

      <a
        className={ styles['social-links__link'] }
        href="https://discord.com/invite/PUaF4NFFwa"
        target="_blank"
        rel="noreferrer"
        title="Discord"
      >
        <FontAwesomeIcon
          icon={ faDiscord }
        />
      </a>
    </div>
  )
}
