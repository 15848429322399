import React from 'react'
import styles from './index.module.scss'

export const TosLink: React.FC = () => {
  return (
    <a
      className={ styles['tos-link__link'] }
      href="https://drive.google.com/file/d/1sbosOyjuncrQBT1LelXwkaZmEUEhEHZK/view"
      target="_blank"
      rel="noreferrer"
      data-testid="tos-link"
    >
      Terms of service
    </a>
  )
}
