import React from 'react'
import type { AppProps } from 'next/app'
import { FlagsProvider } from 'flagged'
import { Toaster } from 'react-hot-toast'
import { ThirdwebProvider, embeddedWallet, metamaskWallet, coinbaseWallet, walletConnect } from '@thirdweb-dev/react'
import { AnalyticsProvider } from '~/components/AnalyticsProvider'
import { ApplicationFooter } from '~/components/ApplicationFooter'
import { NavigationBar } from '~/components/NavigationBar'
import { getSupportedChains } from '~/components/shared/chain'
import { ChainContext } from '~/context/chain'
import { defaultChainId } from '~/constants/chain'
import { thirdwebClientId, walletConnectProjectId } from '~/constants/thirdweb'
import '~/styles/globals.scss'
import 'react-tooltip/dist/react-tooltip.css'

function MarketplaceApp({ Component, pageProps }: AppProps) {
  const [activeChain, setActiveChain] = React.useState<number>(defaultChainId)

  return (
    <ChainContext.Provider value={ { activeChain, setActiveChain } }>
      <ThirdwebProvider
        clientId={ thirdwebClientId }
        activeChain={ activeChain }
        supportedChains={ getSupportedChains() }
        supportedWallets={ [
          embeddedWallet(),
          metamaskWallet(),
          coinbaseWallet(),
          walletConnect({ projectId: walletConnectProjectId }),
        ] }
      >
        <FlagsProvider
          features={ { live: false, legendClub: true, chilizGenesis: false, euro2024: false, paris2024: true } }
        >
          <AnalyticsProvider>
            <>
              <NavigationBar />

              <Component { ...pageProps } />

              <ApplicationFooter />
            </>
          </AnalyticsProvider>
        </FlagsProvider>

        <Toaster
          toastOptions={
            {
              className: '',
              style: {
                borderRadius: '10px',
                background: '#2E3A2C',
                color: '#FFFFFF',
              },
            }
          }
        />
      </ThirdwebProvider>
    </ChainContext.Provider>
  )
}

export default MarketplaceApp
