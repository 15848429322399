import React from 'react'
import { ContactLink } from './ContactLink'
import { CopyrightNotice } from './CopyrightNotice'
import { SocialLinks } from './SocialLinks'
import { PlatformLinks } from './PlatformLinks'
import { CommunityLinks } from './CommunityLinks'
import { TosLink } from './TosLink'
import styles from './index.module.scss'

export const ApplicationFooter: React.FC = () => {
  return (
    <footer
      className={ styles['application-footer__bar'] }
      data-testid="application-footer"
    >
      <div className={ styles['application-footer__container'] }>
        <ContactLink />

        <SocialLinks />

        <PlatformLinks />

        <CommunityLinks />

        <TosLink />

        <CopyrightNotice />
      </div>
    </footer>
  )
}
