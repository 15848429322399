import React from 'react'
import styles from './index.module.scss'

export const ContactLink: React.FC = () => {
  return (
    <a
      className={ styles['contact-link__link'] }
      data-testid="contact-link"
      href="mailto:support@belegends.com"
      target="_blank"
      rel="noreferrer"
    >
      Contact
    </a>
  )
}
