import { environment } from './environment'

export const transakApiKey = process.env.NEXT_PUBLIC_TRANSAK_API_KEY

export const transakEnvironment = environment === 'production'
  ? 'PRODUCTION'
  : 'STAGING'

export const transakRedirectLink = environment === 'production'
  ? 'https://global.transak.com'
  : 'https://global-stg.transak.com'
