import React from 'react'
import Script from 'next/script'
import * as snippet from '@segment/snippet'
import { Router } from 'next/router'
import { useAddress, useEmbeddedWalletUserEmail } from '@thirdweb-dev/react'
import { identify } from './api'
import { sha256 } from 'ethers/lib/utils'

interface Analytics {
  identify: (id: string, traits: Record<string, unknown>) => void
  page: () => void
  track: (event: string, properties: Record<string, unknown>) => void
}

declare global {
  interface Window {
    analytics: Analytics
  }
}

export const AnalyticsProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const address = useAddress()
  const { data: email, isFetching: isFetchingEmail } = useEmbeddedWalletUserEmail()
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    page: true,
  }

  React.useEffect(() => {
    // track client-side page views with Segment
    Router.events.on('routeChangeComplete', (_url) => { window.analytics.page() })
  }, [])

  React.useEffect(() => {
    if (address && !isFetchingEmail) {
      const id = sha256(address)

      if (email) {
        identify(id, email)
      } else {
        identify(id)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isFetchingEmail])

  return (
    <>
      <Script
        id="analytics-script"
        dangerouslySetInnerHTML={ { __html: snippet.min(opts) } }
      />

      { children }
    </>
  )
}
