import React from 'react'
import styles from './index.module.scss'
import Link from 'next/link'

export const CommunityLinks: React.FC = () => {
  return (
    <div
      className={ styles['community-links__container'] }
      data-testid="community-links"
    >
      <p className={ styles['community-links__title'] }>
        Community
      </p>

      <Link
        className={ styles['community-links__link'] }
        href="/paris2024"
      >
        Paris 2024
      </Link>

      <br/>

      <Link
        className={ styles['community-links__link'] }
        href="/euro2024"
      >
        Euro 2024
      </Link>

      <br/>

      <Link
        className={ styles['community-links__link'] }
        href="/club"
      >
        Legend Club
      </Link>

      <br/>

      <Link
        className={ styles['community-links__link'] }
        href="/chiliz"
      >
        Genesis: Legend x Chiliz
      </Link>
    </div>
  )
}
