export {
  identify,
  sendSeriesViewedEvent,
  sendNftViewedEvent,
  sendNftListStartedEvent,
  sendNftListCompletedEvent,
  sendNftListFailedEvent,
  sendNftBuyStartedEvent,
  sendNftBuyCompletedEvent,
  sendNftBuyFailedEvent,
  sendMakeOfferStartedEvent,
  sendMakeOfferCompletedEvent,
  sendMakeOfferFailedEvent,
  sendAcceptOfferStartedEvent,
  sendAcceptOfferCompletedEvent,
  sendAcceptOfferFailedEvent,
  sendCancelOfferCompletedEvent,
  sendCancelOfferFailedEvent,
  sendCancelOfferStartedEvent,
  sendCreateVaultStartedEvent,
  sendCreateVaultCompletedEvent,
  sendCreateVaultFailedEvent,
  sendMatchStakeStartedEvent,
  sendMatchStakeCompletedEvent,
  sendMatchStakeFailedEvent,
  sendMatchUnstakeStartedEvent,
  sendMatchUnstakeCompletedEvent,
  sendMatchUnstakeFailedEvent,
  sendMatchClaimStartedEvent,
  sendMatchClaimCompletedEvent,
  sendMatchClaimFailedEvent
} from './api'
export { AnalyticsProvider } from './provider'
