import React from 'react'
import styles from './index.module.scss'

export const CopyrightNotice: React.FC = () => {
  return (
    <a
      className={ styles['copyright-notice__link'] }
      data-testid="copyright-notice"
      href="https://belegends.com"
      target="_blank"
      rel="noreferrer"
    >
      All rights reserved.
    </a>
  )
}
