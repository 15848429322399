import React from 'react'
import { ConnectWallet } from '@thirdweb-dev/react'
import { HomeLink } from './HomeLink'
import { ProfileLink } from './ProfileLink'
import { BuyCryptoLink } from './BuyCryptoLink'
import styles from './index.module.scss'

export const NavigationBar: React.FC = () => {
  return (
    <div
      className={ styles['navigation-bar__fix'] }
      data-testid="navigation-bar"
    >
      <div className={ styles['navigation-bar__bar'] }>
        <div className={ styles['navigation-bar__container'] }>
          <div>
            <HomeLink />
          </div>

          <div className={ styles['navigation-bar__right'] }>
            <ProfileLink />
            <ConnectWallet />
          </div>
        </div>
      </div>

      <BuyCryptoLink />
    </div>
  )
}
