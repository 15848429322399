import { Item } from '~/domain/Item'
import { Contract } from '~/domain/Contract'
import { NftListing } from '~/domain/NftListing'
import { Series } from '~/domain/Series'
import { CreateVaultStep } from '~/types/CreateVaultStep'
import { Offer } from '~/domain/Offer'
import { MatchPool, Winner } from '~/domain/MatchPool'

export const identify = (id: string, email?: string) => {
  window.analytics.identify(id, { ...(email && { email }) })
}

export const sendSeriesViewedEvent = (series: Series) => {
  window.analytics.track('Series Viewed', {
    id: series.id,
    name: series.name,
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    language: 'en',
  })
}

export const sendNftViewedEvent = (contract: Contract, item: Item, series: Series) => {
  window.analytics.track('Nft Viewed', {
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    language: 'en',
  })
}

type NftListEventName = 'Nft List Started' | 'Nft List Completed' | 'Nft List Failed'

const sendNftListEvent = (
  name: NftListEventName,
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing
) => {
  window.analytics.track(name, {
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    listing: {
      type: listing.type,
      price: {
        value: listing.price.value,
        symbol: listing.price.symbol,
      },
    },
    language: 'en',
  })
}

export const sendNftListStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing
) => {
  sendNftListEvent('Nft List Started', contract, item, series, listing)
}

export const sendNftListCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing
) => {
  sendNftListEvent('Nft List Completed', contract, item, series, listing)
}

export const sendNftListFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing
) => {
  sendNftListEvent('Nft List Failed', contract, item, series, listing)
}

type NftBuyEventName = 'Nft Buy Started' | 'Nft Buy Completed' | 'Nft Buy Failed'

const sendNftBuyEvent = (
  name: NftBuyEventName,
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  buyer?: string
) => {
  window.analytics.track(name, {
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    buyer,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    listing: {
      id: listing.id,
      type: listing.type,
      price: {
        value: listing.price.value,
        symbol: listing.price.symbol,
      },
    },
    language: 'en',
  })
}

export const sendNftBuyStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  buyer?: string
) => {
  sendNftBuyEvent('Nft Buy Started', contract, item, series, listing, buyer)
}

export const sendNftBuyCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  buyer?: string
) => {
  sendNftBuyEvent('Nft Buy Completed', contract, item, series, listing, buyer)
}

export const sendNftBuyFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  buyer?: string
) => {
  sendNftBuyEvent('Nft Buy Failed', contract, item, series, listing, buyer)
}

type MakeOfferEventName = 'Make Offer Started' | 'Make Offer Completed' | 'Make Offer Failed'

const sendMakeOfferEvent = (
  name: MakeOfferEventName,
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  window.analytics.track(name, {
    trophyId,
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    offer: {
      address: offer.address,
      price: {
        value: offer.price,
        symbol: offer.symbol,
      },
    },
    language: 'en',
  })
}

export const sendMakeOfferStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendMakeOfferEvent('Make Offer Started', contract, item, series, offer, trophyId)
}

export const sendMakeOfferCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendMakeOfferEvent('Make Offer Completed', contract, item, series, offer, trophyId)
}

export const sendMakeOfferFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendMakeOfferEvent('Make Offer Failed', contract, item, series, offer, trophyId)
}

type AcceptOfferEventName = 'Accept Offer Started' | 'Accept Offer Completed' | 'Accept Offer Failed'

const sendAcceptOfferEvent = (
  name: AcceptOfferEventName,
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  window.analytics.track(name, {
    trophyId,
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    offer: {
      address: offer.address,
      price: {
        value: offer.price,
        symbol: offer.symbol,
      },
    },
    language: 'en',
  })
}

export const sendAcceptOfferStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendAcceptOfferEvent('Accept Offer Started', contract, item, series, offer, trophyId)
}

export const sendAcceptOfferCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendAcceptOfferEvent('Accept Offer Completed', contract, item, series, offer, trophyId)
}

export const sendAcceptOfferFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendAcceptOfferEvent('Accept Offer Failed', contract, item, series, offer, trophyId)
}

type CancelOfferEventName = 'Cancel Offer Started' | 'Cancel Offer Completed' | 'Cancel Offer Failed'

const sendCancelOfferEvent = (
  name: CancelOfferEventName,
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  window.analytics.track(name, {
    trophyId,
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    type: item.type,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    offer: {
      address: offer.address,
      price: {
        value: offer.price,
        symbol: offer.symbol,
      },
    },
    language: 'en',
  })
}

export const sendCancelOfferStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendCancelOfferEvent('Cancel Offer Started', contract, item, series, offer, trophyId)
}

export const sendCancelOfferCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendCancelOfferEvent('Cancel Offer Completed', contract, item, series, offer, trophyId)
}

export const sendCancelOfferFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  offer: Offer,
  trophyId: string | undefined
) => {
  sendCancelOfferEvent('Cancel Offer Failed', contract, item, series, offer, trophyId)
}

type CreateVaultEventName = 'Create Vault Started' | 'Create Vault Completed' | 'Create Vault Failed'

const sendCreateVaultEvent = (
  name: CreateVaultEventName,
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  fragmentsTotal: number,
  fragmentsToList: number,
  step?: CreateVaultStep
) => {
  window.analytics.track(name, {
    id: item.nft.metadata.id,
    name: item.nft.metadata.name,
    owner: item.nft.owner,
    series: {
      id: series.id,
      name: series.name,
    },
    organizer: {
      id: series.organizer.id,
      acronym: series.organizer.acronym,
    },
    contract: {
      chainId: contract.chainId,
      address: contract.address,
      standard: contract.standard,
      fee: contract.fee,
    },
    listing: {
      type: listing.type,
      price: {
        value: listing.price.value,
        symbol: listing.price.symbol,
      },
    },
    fragments: {
      total: fragmentsTotal,
      listed: fragmentsToList,
    },
    ...(step && { step }),
    language: 'en',
  })
}

export const sendCreateVaultStartedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  fragmentsTotal: number,
  fragmentsToList: number
) => {
  sendCreateVaultEvent(
    'Create Vault Started',
    contract,
    item,
    series,
    listing,
    fragmentsTotal,
    fragmentsToList
  )
}

export const sendCreateVaultCompletedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  fragmentsTotal: number,
  fragmentsToList: number
) => {
  sendCreateVaultEvent(
    'Create Vault Completed',
    contract,
    item,
    series,
    listing,
    fragmentsTotal,
    fragmentsToList
  )
}

export const sendCreateVaultFailedEvent = (
  contract: Contract,
  item: Item,
  series: Series,
  listing: NftListing,
  fragmentsTotal: number,
  fragmentsToList: number,
  step: CreateVaultStep
) => {
  sendCreateVaultEvent(
    'Create Vault Failed',
    contract,
    item,
    series,
    listing,
    fragmentsTotal,
    fragmentsToList,
    step
  )
}

type MatchStakeEventName = 'Match Stake Started' | 'Match Stake Completed' | 'Match Stake Failed'

const sendMatchStakeEvent = (
  name: MatchStakeEventName,
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  window.analytics.track(name, {
    id: pool.id,
    competition: pool.competition,
    contract: pool.contract,
    teamHome: pool.teamHome.name,
    teamAway: pool.teamAway.name,
    wallet,
    prediction: {
      team: prediction === Winner.HOME ? 'home' : 'away',
      amount,
    },
  })
}

export const sendMatchStakeStartedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchStakeEvent('Match Stake Started', pool, wallet, prediction, amount)
}

export const sendMatchStakeCompletedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchStakeEvent('Match Stake Completed', pool, wallet, prediction, amount)
}

export const sendMatchStakeFailedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchStakeEvent('Match Stake Failed', pool, wallet, prediction, amount)
}

type MatchUnstakeEventName = 'Match Unstake Started' | 'Match Unstake Completed' | 'Match Unstake Failed'

const sendMatchUnstakeEvent = (
  name: MatchUnstakeEventName,
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  window.analytics.track(name, {
    id: pool.id,
    competition: pool.competition,
    contract: pool.contract,
    teamHome: pool.teamHome.name,
    teamAway: pool.teamAway.name,
    wallet,
    prediction: {
      team: prediction === Winner.HOME ? 'home' : 'away',
      amount,
    },
  })
}

export const sendMatchUnstakeStartedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchUnstakeEvent('Match Unstake Started', pool, wallet, prediction, amount)
}

export const sendMatchUnstakeCompletedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchUnstakeEvent('Match Unstake Completed', pool, wallet, prediction, amount)
}

export const sendMatchUnstakeFailedEvent = (
  pool: MatchPool,
  wallet: string,
  prediction: Winner,
  amount: string
) => {
  sendMatchUnstakeEvent('Match Unstake Failed', pool, wallet, prediction, amount)
}

type MatchClaimEventName = 'Match Claim Started' | 'Match Claim Completed' | 'Match Claim Failed'

const sendMatchClaimEvent = (
  name: MatchClaimEventName,
  pool: MatchPool,
  wallet: string,
  amount: string
) => {
  window.analytics.track(name, {
    id: pool.id,
    competition: pool.competition,
    contract: pool.contract,
    teamHome: pool.teamHome.name,
    teamAway: pool.teamAway.name,
    wallet,
    winner: pool.winner,
    amount,
  })
}

export const sendMatchClaimStartedEvent = (
  pool: MatchPool,
  wallet: string,
  amount: string
) => {
  sendMatchClaimEvent('Match Claim Started', pool, wallet, amount)
}

export const sendMatchClaimCompletedEvent = (
  pool: MatchPool,
  wallet: string,
  amount: string
) => {
  sendMatchClaimEvent('Match Claim Completed', pool, wallet, amount)
}

export const sendMatchClaimFailedEvent = (
  pool: MatchPool,
  wallet: string,
  amount: string
) => {
  sendMatchClaimEvent('Match Claim Failed', pool, wallet, amount)
}
