import Link from 'next/link'
import React from 'react'
import styles from './index.module.scss'

export const HomeLink: React.FC = () => {
  return (
    <div data-testid="home-link">
      <Link href="/" passHref role="button">
        <img
          className={ styles['home-link__logo'] }
          src={ '/logo.svg' }
          alt="Marketplace Logo"
        />
      </Link>
    </div>
  )
}
